.addqr{
    width: 425px;
    height: 554px;
    background: #FFFFFF;
    border-radius: 9.55224px;
    border: 1px solid white;
    margin: auto;
    margin-top: 89px;
    display: block;
}
.addqr .close-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.addqr .close-container .close{
    margin: 0;
    cursor: pointer;
    width: 72.84px;
    height: 72.84px;
    background: linear-gradient(180deg, #62BA95 0%, #77CAA6 100%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addqr .qr{
    width: 100%;
    padding: 4% 0;
}

.addqr .qr img{
    width: 280px;
    height: 280px;
}

.addqr .button-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.addqr .button-container .cancel{
    width: 144.48px;
    height: 44.18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #EEEEEE;
    border-radius: 9.55224px;
    border: none;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16.7164px;
    line-height: 25px;
    text-align: center;
    color: #414141;
}

.addqr .button-container .jpeg{
    width: 144.48px;
    height: 44.18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
    border-radius: 9.55224px;
    border: none;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16.7164px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
}
.addqr .button-container .jpeg img{
    margin-right: 10px;
}

.addqr .button-container .jpeg a{
    /* margin-right: 10px; */
    text-decoration: none;
    color: #EEEEEE;
}

a{
    text-decoration: none;
}


.addqr>button{
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    gap: 10px;
    width: 160px;
    height: 52px;
    border: none;
    margin: 50px 445px;
    cursor: pointer;
}