.form-container {
  width: 85%;
  max-width: 900px;
  padding: 5%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 20px;
}

.form-container > .form-container-head {
  display: flex;
  justify-content: space-between;
}

.form-container > .form-container-head > h2 {
  margin: auto;
}

.form-container > .form-container-head > .close-container {
  margin: auto 0;
}

.form-container > .form-container-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100% !important;
  max-width: 800px;
  margin: auto;
}

.form-container > .form-container-body > .form-text {
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #606b85;
}

.form-container > .form-container-body > button {
  display: block;
  background: linear-gradient(180deg, #60b893 0%, #7acca9 100%);
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-size: 1rem;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: 160px;
  height: 40px;
  margin: 20px auto 0 auto;
  border: none;
  cursor: pointer;
}

@media only screen and (min-width: 900px) {
  .form-container {
    padding: 3% 5%;
  }
  .form-container > .form-container-body > .small-input {
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    gap: 5%;
  }
}
