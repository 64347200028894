.SubscriptionCard1 {
  display: flex;
  flex-direction: column;
  width: 240px;
  border-radius: 25.89px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0;
}
.SubscriptionCard1 > .type {
  width: 150.17px;
  height: 27.37px;
  margin-top: 25.15px;
  margin-left: auto;
  margin-right:0;
  text-align: center;
  line-height: 27.37px;
  background: linear-gradient(180deg, #5bb58e 0%, #039342 100%);
  border-radius: 22.1928px 0px 0px 22.1928px;
  font-size: 13.32px;
  color: #ffffff;
}
.SubscriptionCard1 > .price {
  font-size: 40.6867px;
  line-height: 100%;
  margin-top: 25.15px;
  margin-left: 33.47px;
  margin-bottom: 0;
  color: #1e9d59;
}
.SubscriptionCard1 > .price > .dollar {
  vertical-align: top;
  position: relative;
  bottom: 9px;
  font-size: 14.7952px;
  margin-top: 0px;
}
.SubscriptionCard1 > .price > .per {
  font-weight: 500;
  font-size: 16px;
  position: relative;
  top: 8px;
}
.SubscriptionCard1 > .details {
  text-align: left;
  margin: 0 10px 10px 35px;
  font-size: 10.3566px;
  line-height: 118.5%;
  color: #000000;
}
.SubscriptionCard1 > ul {
  margin-top: auto;
  padding: 0;
}
.SubscriptionCard1 > ul > li {
  width: 90%;
  padding: 2.5% 5%;
  display: flex;
}
.SubscriptionCard1 > ul > li > div {
  width: 17.75px;
  height: 17.75px;
  background: linear-gradient(180deg, #5bb58e 0%, #039342 100%);
  border-radius: 50%;
}
.SubscriptionCard1 > ul > li > div > img {
  margin: auto;
  filter: invert(100%);
  width: 17px;
  margin-left: 3px;
}
.SubscriptionCard1 > ul > li > p {
  text-align: left;
  margin: auto 0 auto 9px;
  font-weight: 300;
  font-size: 9.87711px;
  line-height: 118.5%;
}
.SubscriptionCard1 > .quantity {
  display: flex;
  width: 80.64px;
  height: 26.88px;
  margin: 0 auto;
}
.SubscriptionCard1 > .quantity > .minus {
  width: 26.88px;
  height: 100%;
  background: #cdf7e4;
  border-radius: 3.36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.SubscriptionCard1 > .quantity > .number {
  width: 26.88px;
  height: 100%;
  text-align: center;
}
.SubscriptionCard1 > .quantity > .plus {
  width: 26.88px;
  height: 100%;
  background: #cdf7e4;
  border-radius: 3.36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.SubscriptionCard1 > .btns {
    display: flex;
  width: 156.84px !important;
  margin: 22px auto 0 auto;
}
.SubscriptionCard1 > .btns > button {
  text-align: center;
  margin: 0 auto 20px auto;
  height: 29.71px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11.1399px;
  color: #000000;
  background-color: white;
  border: 0.928328px solid #2aa264;
  border-radius: 29.7065px;
  cursor: pointer;
}


@media screen and (max-width: 525px) {
  .SubscriptionCard1 {
    width: 85%;
    margin: auto;
  }
}