.payment-success {
  width: 95%;
  max-width: 800px;
  height: auto;
  background: #ffffff;
  border-radius: 17px;
  border: 1px solid black;
  margin: auto;
  margin-top: 89px;
  display: block;
}

.payment-success > .main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment-success > .main-container > h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #000000;
  /* margin-top:61px; */
}
.payment-success > .main-container > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #606b85;
  text-align: center;
}
.payment-success > .main-container > div {
    display: flex;
  width: 207px;
  height: 207px;
  background: #77caa6;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-top: 50px;
}
.payment-success > .main-container > div > img {
  margin: auto;
}
.payment-success > .main-container > button {
  width: 168px;
  height: 52px;
  background: linear-gradient(180deg, #60b893 0%, #7acca9 100%);
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  margin: 40px auto;
}

.payment-success .close-container {
  margin: 10px 20px 0 0;
  text-align: right;
}
.payment-success .close-container p {
  margin: 0;
  cursor: pointer;
}
