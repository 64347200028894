.useform {
  margin: 77px auto 0 auto;
  width: 1014px;
  height: 589px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #f3fffa;
  border-radius: 20px;
}
@media screen and (max-width: 1024px) {
  .useform {
    width: 100%;
    margin: 65px auto 0 auto;
    height: 690px;
  }
}
@media screen and (max-width: 525px) {
  .useform {
    height: auto;
  }
}
@media screen and (max-width: 500px) {
  .useform {
    margin: 0;
    box-shadow: none;
    border-radius: 0px;
    height: unset;
  }
}

@media screen and (min-width: 1441px) {
  .useform {
    width: 80%;
    height: 90vh;
  }
}
