.subscription {
    width: 100%;
    height: calc(100% - 10px);
    padding-top: 10px;
}

.subscription>h2 {
    text-align: center;
    font-size: 16px;
    margin-top: 22px;
    margin-bottom: 30px;
}

.subscription>.carousel {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-around;
}

.subscription>.carousel>.prev-img {
    height: 30px;
    margin: auto 0px auto 40px;
    color: black;
    cursor: pointer;
    padding: 16px;
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.subscription>.carousel>.next-img {
    height: 30px;
    margin: auto 40px auto 0px;
    color: black;
    cursor: pointer;
    padding: 16px;
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.subscription>.carousel>.prev-img:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.subscription>.carousel>.next-img:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.subscription>.next {
    margin-top: 36px;
    margin-left: auto;
    margin-right: auto;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    line-height: 68px;
    text-align: center;
    color: white;
    background: linear-gradient(180deg, #78CBA8 0%, #6ABF9C 100%);
    font-size: 35px;
    cursor: pointer;
}

@media screen and (max-width: 525px) {
    .subscription>.carousel {
        flex-wrap: wrap;
        grid-gap: 20px;
    }
}