.delete-user{
    width: 425px;
    height:290px;
    background: #FFFFFF;
    border-radius: 9.55224px;
    padding-top:40px;
}
.delete-user>.delete-img{
    width: 72.84px;
    height: 72.84px;
    background: linear-gradient(180deg, #62BA95 0%, #77CAA6 100%);
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}
.delete-user>.delete-img>img{
    width: 24px;
    height: 24px;
    margin: auto;
    position: relative;
    top:24.42px;
}
.delete-user>p{
    font-family:'Poppins';
    font-style: normal;
    font-weight: 500;
    width: 100%;
    height: 49px;
    color: #000000;
    font-size: 16.7164px;
    margin: 31.1px auto 42px auto;
}
.delete-user>.btns{
  height:44.18px;
  width: 310.45px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.delete-user>.btns>button{
    border-radius: 9.55224px;
    height: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16.7164px;
    width:144.48px;
    border: none;
    font-weight:600;
    cursor: pointer;
}
.delete-user>.btns>.left-btn{
    background: #EEEEEE;
}
.delete-user>.btns>.right-btn{
    color:#FFFFFF;
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
}