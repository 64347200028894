.profile{
    width:calc(100% - 359px);
    padding-left: 55px;
    padding-top: 50px;
    background-color:  #F5F5F5;
    padding-bottom: 100px;
}
.profile>h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 100%;
    color: #000000;
}

.profile>p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 150%;   
    color: #606B85;
    margin-top: -20px;
}    
.profile .input>div{
    width: auto;
    padding-left: 18px;
}
.profile>.profile-card{
    width: 100%;
    /* height: 100vh; */
    background: #FFFFFF;
    border-radius: 12px;
    margin-top:30px;
}
.profile>.profile-card>.profile-details{
    width:calc(100% - 38px);
    padding-left: 38px;
    padding-top: 24px;
    
}
.profile>.profile-card>.profile-details>h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;    
    color: #000000;
}
.profile>.profile-card>.profile-details>.details-input{
    display: flex;
    gap: 20px;
}
.profile>.profile-card>.profile-details>.details-input>.left-details{
    width:50%;
}
.profile>.profile-card>.profile-details>.details-input>.right-details{
    width:50%;
}
.profile>.profile-card>.address-details{
    width:calc(100% - 38px);
    padding-left: 38px;
    padding-top: 24px;
    
}
.profile>.profile-card>.address-details>h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;    
    color: #000000;
}
.profile>.profile-card>.address-details>.details-input{
    display: flex;
    justify-content: space-between;
}
.profile>.profile-card>.address-details>.details-input>.left-details{
    width:378px;
}
.profile>.profile-card>.address-details>.details-input>.right-details{
    width:378px;
    margin-right:150px;
}
.profile>.profile-card>.company-details{
    width:calc(100% - 38px);
    padding-left: 38px;
    padding-top: 24px;
    
}
.profile>.profile-card>.company-details>h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;    
    color: #000000;
}
.profile>.profile-card>.company-details>.details-input{
    display: flex;
    justify-content: space-between;
}
.profile>.profile-card>.company-details>.details-input>.left-details{
    width:50%;
}
.profile>.profile-card>.company-details>.details-input>.right-details{
    width:50%;
    /* margin-right:150px; */
}
.profile>.profile-card>.subscription-details{
    width:calc(100% - 38px);
    padding-left: 38px;
    padding-top: 24px;
    
}
.profile>.profile-card>.subscription-details>h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;    
    color: #000000;
}
.profile>.profile-card>.subscription-details>.details{
    width:92%;
    height: 86px;
    background: #EEF7F3;
    border-radius: 63px;
}
.profile>.profile-card>.subscription-details>.details>img{
    float: left;
    width: 24px;
    height: 24px;
    margin: 31px 20px 31px 50px;
}
.profile>.profile-card>.subscription-details>.details>p{
    float: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 100%;
    color: #1E9D59;
    margin-top: 23px;
}
.profile>.profile-card>.subscription-details>.details>button{
    float: right;
    width: 141px;
    height: 30px;
    background: linear-gradient(180deg, #6FBF9D 0%, #039342 100%);
    border-radius: 29.712px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11.142px;
    color: #FFFFFF;
    border:none;
    margin: 28px 35px 28px 0px;
}
.profile>.profile-card>.payment-method{
    width:calc(100% - 38px);
    padding-left: 38px;
    padding-top: 24px;
    
}
.profile>.profile-card>.payment-method>h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;    
    color: #000000;
}
.profile>.profile-card>.payment-method>.details{
    width:100%;
    display: flex;
    height: 100px;
    position: relative;
}
.profile>.profile-card>.payment-method>.details>.green{
    width: 24px;
    height: 24px;
    margin: 45px 20px 16px 4px;
}
.profile>.profile-card>.payment-method>.details>.visa{
    width: 42px;
    height: 29px;
    position: relative;
    top:51px;
    right:61px;
}
.profile>.profile-card>.payment-method>.details>.add{
    width:20px;
    height:20px;
    position: absolute;
    left:530px;
    top:54px;
    cursor: pointer;
    z-index: 1;
}
.profile>.profile-card >.payment-method>.payment-method-header {
    display: flex;
    justify-content: space-between;
}

.profile>.profile-card>.payment-method>.details>button{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    width: 121px;
    height: 37px;
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
    border-radius: 44px;
    margin: auto 0px auto 10px;
    position: relative;
    top:14px;
    padding-left:40px;
    border: none;
    cursor: pointer;
}

.profile>.profile-card>.other-details{
    width:calc(100% - 38px);
    padding-left: 38px;
    padding-top: 24px;
    
}
.profile>.profile-card>.other-details>h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;    
    color: #000000;
}
.profile>.profile-card>.other-details>div{
    display: flex;
    justify-content: space-between;
}
.profile>.profile-card>.other-details>div>div{
    width:378px;
}

@media screen and (max-width:1024px){
    .profile{
        padding-right: 55px;
    }
    .profile .input>input{
        width: 70% !important;
    }
    .double-input{
        flex-wrap: wrap;
        width: 100% !important;
    }
    .double-input-left{
        margin-bottom: 15px;
    }
    .double-input>div{
        width: 100% !important;
    }
    .double-input input{
        width: 100% !important;
    }
    .double-input-right input{
        width: 90% !important;
    }
}

@media screen and (max-width: 767px) {
    .profile {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .profile>.profile-card{
        width: 100%;
        height: 100vh;
    }
    .profile>.profile-card>.profile-details>.details-input>.left-details, .profile>.profile-card>.profile-details>.details-input>.right-details {
        width: 50%;
    }
    .profile .input>input {
        width: 100% !important;
        min-width: 100% !important;
    }
    .profile>.profile-card>.profile-details, .profile>.profile-card>.company-details, .profile>.profile-card>.payment-method{
        padding-left: 12px !important;
    }
    .profile>.profile-card>.company-details>.details-input{
        flex-wrap: wrap;
    }
    .profile>.profile-card>.company-details>.details-input>.left-details, .profile>.profile-card>.company-details>.details-input>.right-details{
        width: 100%;
    }
    .double-input>.double-input-left{
        margin-right: 0;
    }
    .double-input-right input {
        width: 100% !important;
    }
    .profile>.profile-card{
        height: auto;
    }
}

@media screen and (max-width: 500px) {
    .profile>.profile-card>.profile-details>.details-input {
        flex-wrap: wrap;
    }
    .profile>.profile-card>.profile-details>.details-input>.left-details, .profile>.profile-card>.profile-details>.details-input>.right-details {
        width: 100%;
    }
}