.signup-form{
    width:100%;
    height:calc( 100% - 10px );
    padding-top:10px;
}
.signup-form>h2{
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    margin-top: 61px;
}
.signup-form>p{
    margin-top: 14px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 30px;
}
.signup-form>button{
    background: linear-gradient(180deg, #6FBF9D 0%, #039342 100%);
    border-radius: 32px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding:9px 23px 9px 23px;
    font-size: 13px;
    color:white;
    border: none;
    cursor: pointer;
    margin-top: 38px;
}