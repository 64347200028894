.header {
  width: 100%;
  height: 79px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  position: fixed;
  z-index: 5;
  top: 0px;
  left: 0px;
}
.header > .topicon {
  width: 79px;
  height: 79px;
  cursor: pointer;
}
.header > .title {
  height: 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16.8085px;
  line-height: 45.5px;
  white-space: nowrap;
  text-align: center;
  color: #039342;
  cursor: pointer;
}
.header > .search {
  width: 400px;
  height: 42px;
  margin: auto 0px auto calc(100% - 840px);
}
.header > .bell {
  width: 48px;
  height: 48px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
}
.header > .topright {
  width: 52px;
  height: 52px;
  background: #109643;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 100px;
  border-radius: 50%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.header > .topright > img {
  width: 36px;
  height: 43px;
  margin-left: 8px;
  margin-top: 10px;
  margin-right: 55px;
}
.dropdown {
  position: fixed;
  right: 40px;
  top: 70px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  z-index: 6;
  border-radius: 5px;
  width: 120px;
}
.dropdown ul {
  margin: 0;
  padding: 0;
}
.dropdown ul li {
  list-style: none;
  cursor: pointer;
  margin: 10px;
  padding: 0;
}
.dropdown ul li:hover {
  text-decoration: underline;
}

@media screen and (max-width: 767px) { 
  .header > .topright {
    margin-left: auto;
  }
}
