.subscriptions {
  width: 95%;
  max-width: 800px;
  height: 75vh;
  background: #ffffff;
  border-radius: 17px;
  border: 1px solid black;
  margin: auto;
  margin-top: 89px;
  display: block;
}
.subscriptions .close-container {
  margin: 10px 10px 0 0;
  text-align: right;
}
.subscriptions .close-container p {
  margin: 0;
  cursor: pointer;
}
.subscriptions > h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
}
.subscriptions > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #606b85;
  margin-top: -15px;
}
.subscriptions > div > .sub-card {
  display: flex;
  margin-left: 70px;
}
.subscriptions > div > .sub-card > img {
  margin-right: 27px;
}
.subscriptions > button {
  display: block;
  background: linear-gradient(180deg, #60b893 0%, #7acca9 100%);
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: 160px;
  height: 52px;
  margin: 20px auto;
  color: #ffffff;
  gap: 10px;
  border: none;
  cursor: pointer;
}

.subscriptions .main-container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.subscriptions .main-container .buynow {
  border: 1px solid rgb(222, 217, 217);
  background-color: #caced6;
  border-radius: 17px;
  padding: 50px;
}

.subscriptions .main-container .subscription-table {
  display: flex;
  flex-direction: column;
  height: 400px;
  /* border: 1px solid red; */
  overflow-x: scroll;
}

.subscriptions .main-container .subscription-table > table > thead > tr {
  background: #f3f4f7;
}

.subscriptions .main-container .subscription-table > table > thead > tr > th {
  text-align: center;
  height: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9aa6b9;
}

.subscriptions .main-container .subscription-table > table > tbody > tr > td {
  text-align: center;
  height: 30px;
  background-color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9aa6b9;
}

.subscriptions
  .main-container
  .subscription-table
  > table
  > tbody
  > tr
  > td
  > button {
  width: 107px;
  height: 22px;
  background: #5bb58e;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
}
.subscriptions .main-container .subscription-table button {
  border: none;
  cursor: pointer;
  width: 155px;
  height: 29px;
  margin: 20px auto;
  background: linear-gradient(91.94deg, #5bb58e -3.74%, #6fbf9d 101.3%);
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
}

.subscriptions .main-container .buynow p {
  font-size: 20px;
  color: #ffffff;
}

.subscriptions .main-container .buynow button {
  background: linear-gradient(180deg, #60b893 0%, #7acca9 100%);
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  gap: 10px;
  width: 160px;
  height: 52px;
  border: none;
  cursor: pointer;
}
