.adding-product {
  width: 95%;
  height: 75vh;
  background: #ffffff;
  border-radius: 17px;
  border: 1px solid black;
  margin: auto;
  margin-top: 89px;
  display: block;
}
.adding-product .close-container {
  margin: 10px 20px 0 0;
  text-align: right;
}
.adding-product .close-container p {
  margin: 0;
  cursor: pointer;
}
.adding-product > h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  /* margin-top: 40px; */
}
.adding-product > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #606b85;
  margin-bottom: 40px;
}
.adding-product > button {
  background: linear-gradient(180deg, #60b893 0%, #7acca9 100%);
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  gap: 10px;
  width: 160px;
  height: 52px;
  border: none;
  margin: 50px 445px;
  cursor: pointer;
}
