.modal-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.50);
}
.modal-wrapper>.modal-form {
    position: relative;
    width: 90%;
    max-width:400px;
    padding: 2.5%;
    margin: auto;
    border-radius: 15px;
    overflow: hidden;
    z-index: 1;
}
.modal-wrapper > .modal-form > .form-body {
    margin: 20px 0;
    text-align: center;
}
