.company-detail{
    width:100%;
    height:calc( 100% - 10px );
    padding-top:25px;
}
.company-detail>h2{
    text-align: center;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.company-detail>.next{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    line-height: 68px;
    text-align: center;
    color: white;
    background: linear-gradient(180deg, #78CBA8 0%, #6ABF9C 100%);
    font-size: 35px;
    cursor: pointer;
}