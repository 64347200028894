.login{
    width:calc( 100% - 40px );
    height:calc( 100% - 10px );
    padding: 0 20px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.login>img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: -50px;
    /* border: 1px solid red; */
}
.login>h2{
    text-align: center;
    font-size: 28px;
    margin-bottom: 0;
}
.login>.logintop{
    text-align: center;
    font-size: 19px;
}
.login>p{
    text-align: center;
}
.login>.email{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 380px;
    font-size: 15px;
}
.login>input{
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    width: 380px;
    padding: 0 20px;
    height: 45px;
    border: none;
    border-radius: 30px;
}

.login .input-box .forgot{
    font-size: 13px;
    cursor: pointer;
    color: #73C7A4;
    text-align: right;
}
.login>.email>img{
    width:12px;
    margin-right: 8px;
}
.login>button{
    background: linear-gradient(180deg, #6FBF9D 0%, #039342 100%);
    border-radius: 32px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding:10px 35px 8px 35px;
    border-radius:20px;
    font-size: 15px;
    color:white;
    border: none;
    cursor: pointer;
}
.login>.google-login{
    cursor:pointer;
    display: flex;
    justify-content: space-between; 
    width:250px;
    background:rgb(255, 255, 255,1);
    height:30px;
    margin:15px auto;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 8px 0px 5px 0px;
}
.login>.google-login:hover{
    background:rgb(252, 252, 252,1);
}
.login>.google-login>img{
    width:20px;
    height:20px;
    margin:5px 0px 5px 20px;
}
.login>.google-login>p{
    margin:5px 65px 5px 0px;
    height:20px;
    line-height: 20px;
}
.login>.new{
    font-size: 16px;
    line-height: 24px;
}
.login>.new>span{
    color:#73C7A4;
    cursor: pointer;
}
.login .input-box {
    width: 100%;
}
.login .input-box .forgot {
    margin-left: 240px;
    margin-top: 20px;
    text-align: center;
}
@media screen and (max-width: 500px) {
    .login .input-box .forgot {
        text-align: right;
        margin-left: 0;
    }
}
@media screen and (max-width: 991px) {
    .login {
        padding-top: 35px;
    }
}
@media screen and (max-width: 250px) {
    .login>.google-login{
        width:100%;
        margin-left:0;
        margin-right:0;
        justify-content: space-evenly; 
    }
    .login>.google-login>img{
        margin-left:0;
        margin-right:0;
    }
    .login>.google-login>p{
        margin-left:0;
        margin-right:0;
    }
}

