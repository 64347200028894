.input{
    margin-top:21px;
}
.input>div{
    display: flex;
    margin-left: auto ;
    margin-right: auto;
    width:344px;
    font-size:15px;
    height: 18px;
}
.input>div>p{
    margin-top:-3px;
}
.input>input{
    margin-top:5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size:15px;
    width:344px;
    padding: 0 17px;
    height:44px;
    border: none;
    border-radius: 59px;
}
.input>textarea{
    margin-top: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    width: 344px;
    padding: 0 17px;
    height: 44px;
    border: none;
    border-radius: 59px;
    font-family: 'Poppins';
}
.input>div>img{
    width:13.6px;
    margin-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
}