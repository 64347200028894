.about{
    width:100%;
    height:calc( 100% - 10px );
    padding-top:25px;
}
.about>h2{
    text-align: center;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.about>.next{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    line-height: 68px;
    text-align: center;
    color: white;
    background: linear-gradient(180deg, #78CBA8 0%, #6ABF9C 100%);
    font-size: 35px;
    cursor: pointer;
    /* border: 1px solid red; */
}
.about .login-section{
    /* border: 1px solid red; */
    margin-top: 0;
}
.about .login-section p{
    margin: 0;
    text-align: center;
}
.about .login-section p span{
    color: #039342;
    cursor: pointer;
}