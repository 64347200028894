.signup-complete{
    width:100%;
    height:calc( 100% - 10px );
    padding-top:10px;
}
.signup-complete>h2{
    text-align: center;
    font-size: 24px;
    margin-top:61px;
}
.signup-complete>p{
    font-size: 16px;
    text-align: center;
}
.signup-complete>div{
    width:79px;
    height:44px;
    background-color:rgb(109, 224, 161);
    margin-left: auto;
    margin-right: auto;
    padding:40px 35px 75px 45px;
    border-radius: 50%;
    margin-top: 50px;
}
.signup-complete>div>img{
    width:80px;
    height:80px;
    filter:invert(100%);
}
.signup-complete>button{
    background: linear-gradient(180deg, #6FBF9D 0%, #039342 100%);
    border-radius: 32px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding:10px 35px 8px 35px;
    font-size: 13px;
    color:white;
    border: none;
    margin-top: 57px;
    cursor: pointer;
 }