.choose-method{
    width: 90%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 17px;
    border: none;
    margin: auto;
    padding-top: 0.2px;
    display: block;
}
.choose-method .close-container {
     margin: 10px 20px 0 0;
     text-align: right; 
     /* border: 1px solid red; */
     width: 100%;
     display: flex;
     justify-content: flex-end;
}
.choose-method .close-container p{
         margin: 0 5px ;
         cursor: pointer;
 }
.choose-method>h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
    margin-top: 40px;
}
.choose-method>p{ 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #606B85;
    margin-bottom:40px;
}
.choose-method>div{
    width:550px;
    display: flex;
    justify-content: space-between;
    margin: 120px auto 0px auto;
}
.choose-method>div>div{
    width: 244px;
    height: 229.9px;
}
.choose-method>div>div>p{
    width: 180px;
    height: 49px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16.7164px;
    text-align: center;
    color: #000000;
    margin: -35px auto 0px;
}
.choose-method>div>div>button{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    width: 144.48px;
    height: 40.18px;
    border: none;
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
    border-radius: 9.55224px;
    margin: 25px auto 0px;
    cursor: pointer;
}