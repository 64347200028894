.double-input {
  display: flex;
  width: 50%;
  font-size: 15px;
  margin-top: 21px;
  margin-left:auto;
  margin-right: auto;
}

.double-input > div {
  width: 172px;
}

.double-input > div > div {
  width: 100%;
  display: flex;
  font-size: 15px;
  height: 18px;
  margin-left: 17px;
}

.double-input > .double-input-left {
  margin-right: 40px;
}

.double-input > div > div > img {
  width: 13.6px;
  font-size: 15px;
  margin-right: 8px;
  height: 11.99px;
  margin-top: auto;
  margin-bottom: auto;
}

.double-input > div > div > p {
  margin-top: -3px;
}

.double-input > div > input {
  margin-top: 5px;
  display: block;
  font-size: 15px;
  width: 138px;
  padding: 0 17px;
  height: 44px;
  border: none;
  border-radius: 59px;
}

@media screen and (max-width: 1024px) {
  .signup-step .double-input > .double-input-left > div {
    width: 344px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .signup-step .double-input > .double-input-left > input {
    width: 344px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .signup-step .double-input > .double-input-right > div {
    width: 344px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .signup-step .double-input > .double-input-right > input {
    width: 344px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
