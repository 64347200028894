.modal-form-input {
  width: 100%;
  margin: 21px auto 0 auto;
}

.modal-form-input > .label > img {
  width: 12px;
  margin: auto 8px auto 0;
}
.modal-form-input > .label > label {
  font-size: 0.9rem;
}

.modal-form-input > .main-style {
  box-sizing: border-box;
  display: block;
  width: 100% !important;
  height: 35px;
  padding: 0 17px;
  margin: 7px 0 0 0;
  font-size: 15px;
  border: none;
  border-radius: 59px;
  font-family: "Poppins";
  background: #f5f5f5;
}

.modal-form-input > textarea {
  display: block;
  width: 100%;
  border-radius: 10px !important;
  height: 100px !important;
  padding: 10px !important;
}
.modal-form-input > .select-style {
  display: flex;
  position: relative;
}
.modal-form-input > .select-style > p {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto 0;
}
.modal-form-input > .select-style > .option-list {
  position: absolute;
  list-style: none;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  background: #ffffff;
  height: 0px;
  overflow-y: scroll;
  transition: all 0.3s ease-in;
}
.modal-form-input > .select-style > .option-list > li {
  display: block;
  font-size: 0.8rem;
  width: 95%;
  padding: 2.5%;
  white-space: nowrap;
  cursor: pointer;
}

.modal-form-input > .select-style > .active {
  height: 150px;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.08);
}
