.view-client{
    width: 425px;
    height:auto;
    background: #FFFFFF;
    border-radius: 9.55224px;
    margin: auto;
}

.view-client .header-container{
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-around;
    margin: 0;
    /* width: 95%; */
    padding-right: 10px;
}

.view-client .header-container p{
    cursor: pointer;
}
.view-client .header-container .top{
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    width: 172.82px;
    /* padding: 10px; */
    height: 31.88px;
    margin: 11px auto 0px auto;
}
.view-client .header-container .top p{
    margin: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16.7164px;
}
.view-client .header-container .top img{
    width:27px;
    height:28px;
    margin: auto;
    cursor: pointer;
}
.view-client>.client-name{
    width: 328px;
    padding: 15px 11.5px;
    background: #FFFFFF;
    border-radius: 9.55224px;
    margin:0px auto;
}
.view-client>.client-name>.name{
    width: 296px;
    height: 25px;
    padding: 10px 13px 12px 22px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F5F5F5;
    margin-top: 2px;
}
.view-client>.client-name>.name>p{
    width: 272px;
    height: 25px;
    margin:0px;
    text-align: left;
}
.view-client>.client-name>.name>img{
    width:12.92px;
    height:16.87px;
    position: relative;
    top:3px;
    cursor: pointer;
}