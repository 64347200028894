.addUser{
    width: 100%;
    height: 337.60px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.addUser .header-container{
    /* border: 1px solid red; */
    padding-top: 0;
    margin-top: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.addUser .header-container h2{
    font-family:'Poppins';
    font-style: normal;
    font-weight: 1000;
    font-size: 16.3571px;
    line-height: 16.3571px;
    text-align: center;
    color: #000000;
    margin: 10px;
}
.addUser .input-container input{
    padding: 6.81548px 6.81548px 6.81548px 17.0387px;
    gap: 6.82px;
    width: 286.24904px;
    height: 40.889px;
    color: #7E7E7E;
    background: #F5F5F5;
    border-radius: 8.17857px;
    border:none;
    margin-top: 8.86px;
    /* border: 1px solid red; */
}

.addUser .footer-container {
    display: flex;
    width: 100%;
    /* border: 1px solid red; */
    position: relative;
    margin-top: 20px;
    justify-content: space-around;
}
.addUser .footer-container .btn{
    width: 114.5px;
    height: 35.44px;
    border-radius: 8px;
    border: none;
    /* margin: 30px auto 0 auto; */
    cursor: pointer;
}
.addUser .footer-container .btn button{
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
    border-radius: 5.45238px;
    /* padding-left: 25px; */
    height: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10.9048px;
    color:#FFFFFF;
    width: 114.5px;
    border: none;
    font-weight:600;
    cursor: pointer;
}
.addUser .footer-container .btn img{
    position: absolute;
    width:13.63px;
    height:13.63px;
    top: 10.905px;
    left: 260px;
    /* border: 1px solid red; */
}