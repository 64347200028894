.edit-project {
  width: calc(100% - 359px);
  padding-left: 49px;
  background-color: #f5f5f5;
  padding-bottom: 100px;
  padding-top: 40px;
}
.edit-project > h2 {
  font-family: "Inter";
  font-weight: 200;
  font-style: normal;
  font-size: 34px;
}
.edit-project > p {
  font-family: "poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 150%;
  color: #606b85;
  margin-top: -24px;
  margin-left: 0px;
}
.edit-project > .btns {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  margin-bottom: 20px;
}
.edit-project > .btns > div {
  position: relative;
}

.edit-project > .btns > .save {
  display: flex;
  width: fit-content;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: auto 0;
}
.edit-project > .btns > .save > input {
  font-size: 14px;
  color: black;
  width: 269px;
  padding: 10px 14px;
  border: 1px solid #c9c9c9;
  border-radius: 11px;
}

.tool-tip {
  width: fit-content;
  position: relative;
}

.tool-tip > span {
  position: absolute;
  left: 0;
  bottom: 150%;
  width: 220px;
  white-space: pre-wrap;
  display: none;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px 10px rgba(0.08, 0, 0, 0),
    -2px -2px 10px 10px rgba(0.08, 0, 0, 0);
  color: #606b85;
  text-align: left;
  font-size: 0.7rem;
}

.tool-tip:hover > span {
  display: block;
}

.edit-project > .btns > .tool-tip {
  display: flex;
}

.edit-project > .btns > .tool-tip > div {
  display: flex;
  width: fit-content;
  background: linear-gradient(180deg, #60b893 0%, #7acca9 100%);
  border-radius: 8px;
  padding: 8px;
  margin: 10px 10px 10px 0;
}

.edit-project > .btns > .tool-tip > div > img {
  width: 18px;
  height: fit-content;
  margin: auto 10px auto 0;
}

.edit-project > .btns > .tool-tip > div > button {
  display: block;
  background: transparent;
  white-space: nowrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: auto 0;
}

/*.edit-project > .btns > .tool-tip > .btn-left > button {
  display: flex;
  width: fit-content;
}

.edit-project > .btns > .tool-tip > .btn-middle > button {
  display: flex;
  width: fit-content;
}*/

.edit-project > .btns > .btn-right > button {
  width: 135px;
  padding-left: 33px;
}
.edit-project > .btns > .btn-right > img {
  position: absolute;
  left: 17px;
  top: 8px;
}

.edit-project > .project {
  margin-top: 30px;
  border-radius: 5px;
  width: calc(99% - 72px);
  position: relative;
}
.edit-project > .project > .bg {
  position: absolute;
  top: 42.5px;
  width: 100%;
  height: 13px;
  background-color: #f5f5f5;
  padding-left: 40px;
  z-index: 0;
}
.edit-project > .section {
  display: flex;
  width: calc(99% - 42px);
  padding: 8px 5px;
  border-radius: 7px;
  height: auto;
  background-color: #ffffff;
  overflow-y: hidden;
}

.section::-webkit-scrollbar {
  height: 2px;
}
.section::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #d3d3d3;
}
.section::-webkit-scrollbar-thumb {
  background: #67bd99;
  border-radius: 33.6507px;
}
.section::-webkit-scrollbar-thumb:hover {
  background: #51a582;
}
.edit-project > .section > .section-div {
  display: flex;
  /*justify-content: center;
    align-items: center;*/
  width: 126px !important;
  padding-left: 10px;
  height: fit-content;
}
.edit-project > .section > .section-div > p {
  margin: auto 0;
  padding: 2px 0px;
  height: 21px;
  width: 101px;
  text-align: center;
  font-size: 14px;
  color: #606b85;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  cursor: pointer;
}
.edit-project > .section > .section-div > img {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: auto;
}
.edit-project > .section > .add-section {
  display: flex;
  width: 123px !important;
  padding-left: 10px;
  border-left: 1px solid #eee;
  cursor: pointer;
}
.edit-project > .section > .add-section > img {
  display: flex;
  height: 25px;
  width: 25px;
  margin: auto 0px;
  margin-right: 10px;
  cursor: pointer;
}
.edit-project > .section > .add-section > p {
  margin: auto 0;
  padding: 0px;
  white-space: nowrap;
  font-size: 14px;
  color: #606b85;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  cursor: pointer;
}

.edit-project > .project > .content-wrapper {
  display: flex;
  position: relative;
  background-color: #ffffff;
  padding: 0px;
  width: calc(100% + 40px);
  height: auto;
  z-index: 0;
  border-radius: 10px;
}

.content-wrapper ::-webkit-scrollbar {
  width: 3.96px;
}
.content-wrapper ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: #f2f2f2;
}
.content-wrapper ::-webkit-scrollbar-thumb {
  background: #67bd99;
  border-radius: 33.6507px;
}
.content-wrapper ::-webkit-scrollbar-thumb:hover {
  background: #51a582;
}

/*
.edit-project>.project>.content-wrapper>.content{
    background-color: #FFFFFF;
    height: 600px;
    overflow-y: scroll;
}
.edit-project>.project>.content-wrapper>.content::-webkit-scrollbar {
    width: 3.96px;
}  
.edit-project>.project>.content-wrapper>.content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    background: #F2F2F2;
}
.edit-project>.project>.content-wrapper>.content::-webkit-scrollbar-thumb {
    background: #67BD99;
    border-radius: 33.6507px;
}
.edit-project>.project>.content-wrapper>.content::-webkit-scrollbar-thumb:hover {
    background: #51a582;
}
*/

.edit-project .project .draftorpublished {
  width: calc(100% + 40px);
  height: 31px;
  background: linear-gradient(90.07deg, #6ac09c -0.03%, #76c9a6 100.51%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-project .project .draftorpublished p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}

/**
update froala 
*/
.fr-top {
  border: transparent;
}
.fr-wrapper {
  width: 100%;
  border: transparent !important;
}
.fr-second-toolbar {
  border: transparent;
}

@media screen and (max-width: 1024px) {
  .edit-project {
    width: calc(100% - 290px);
  }
  .edit-project > .project > .content-wrapper {
    height: 724px;
  }
  .edit-project > .section {
    margin-top: 0px;
  }

  .edit-project > .btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .edit-project {
    width: 100%;
    padding-left: 20px;
  }

  .edit-project > .project > .save > input {
    width: 50%;
  }
  .edit-project > .project > .save {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: unset;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 450px) {
  .edit-project > .project > .content-wrapper {
    height: 748px;
  }
}

@media screen and (max-width: 365px) {
  .edit-project > .project > .content-wrapper {
    height: 772px;
  }
}

@media screen and (min-width: 380px) {
  .edit-project > .btns > .tool-tip:nth-child(2) > div {
    margin-left: 10px;
  }
}
