.signup-step{
    width:100%;
    height:100%;
    display: flex;
    overflow:hidden;
}
.signup-step>.left-side{
    background-color: white;
    width:372px;
    height:calc( 100% - 10px );
    padding-top: 10px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: 1px solid rgb(195, 195, 255);
}
.signup-step>.left-side>.page-circle{
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    line-height: 88px;
    text-align: center;
    color: black;
    border: 6px solid #ECF6FF;
    background-color:#FFFFFF;
    font-weight: 700;
    font-size: 20px;
}
.signup-step>.left-side>p{
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 500;
}
.signup-step>.left-side>ul{
    list-style-type: none;
    padding: 0;
}
.signup-step>.left-side>ul>li{
    background-color:  #ECF6FF;;
    margin:26px auto 0px auto;
    width:199px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 40px;
    cursor: pointer;
}
.signup-step>.left-side>ul>li>img{
    width:12px;
    margin-right: 15px;
}
.signup-step>.right-side{
    width:calc( 100% - 30px);
    padding:15px;
    height:100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
