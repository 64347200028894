.add-product{
    width: 95%;
    max-width:800px;
    height: 75vh;
    background: #FFFFFF;
    border-radius: 17px;
    border: 1px solid black;
    margin: auto;
    margin-top: 89px;
    display: block;
    overflow-y:scroll;
}

.add-product .close-container {
    margin: 10px 10px 0 0;
    text-align: right;
}
.add-product .close-container p{
    margin: 0;
    cursor: pointer;
}
.add-product>h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
}
.add-product>p{ 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #606B85;
    margin-top:-15px;
}
.add-product .main-container{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 70px;
}
.add-product>.main-container>.sub-card{
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    max-width:250px;
    margin-bottom: 20px;
}
.add-product>.main-container>.sub-card>img{
    margin-right: 27px;
}
.add-product>button{
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    gap: 10px;
    width: 160px;
    height: 52px;
    border: none;
    margin: 20px 445px;
    cursor: pointer;
}

@media only screen and (min-width:800px) {
  .add-product {
    overflow-y: hidden;
  }
}