.payment-product{
    width: 95%;
    height: auto;
    background: #FFFFFF;
    border-radius: 17px;
    border: 1px solid black;
    margin: auto;
    margin-top: 89px;
    display: block;
}
.payment-product .close-container {
    margin: 10px 20px 0 0;
    text-align: right;
}
.payment-product .close-container p{
    margin: 0;
    cursor: pointer;
}
.payment-product>h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
    /* margin-top: 40px; */
}
.payment-product>p{ 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #606B85;
    margin-bottom:40px;
}
.payment-product>.check{
    width:260px;
    margin: 10px auto 40px 290px;
}
.payment-product>.check>label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 150%;
    color: #606B85;
}
.payment-product>button{
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    gap: 10px;
    width: 160px;
    height: 52px;
    border: none;
    margin: 20px 445px;
    cursor: pointer;
}