@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");
.teamProductCard {
  width: calc(100% - 359px);
  padding-left: 49px;
  padding-top: 50px;
  background-color: #f5f5f5;
  padding-bottom: 100px;
}
.teamProductCard > .top {
  width: 95%;
  display: flex;
  height: 89px;
}
.teamProductCard > .top > .left-side {
  width: 60%;
  height: 100%;
}
.teamProductCard > .top > .right-side {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.teamProductCard > .top > .right-side > div:nth-child(1) {
  margin-right: 20px;
}
.teamProductCard > .top > .left-side > h2 {
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  font-size: 34px;
  margin-top: 0px;
}
.teamProductCard > .top > .left-side > p {
  font-family: "poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 150%;
  color: #606b85;
  margin-top: -24px;
  margin-left: 0px;
}
.teamProductCard > .top > .right-side {
  width: 50%;
}
.teamProductCard > .top > .right-side > div {
  width: 35%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4.87879px 125.629px rgba(50, 50, 71, 0.01),
    0px 4.87879px 71.9621px rgba(50, 50, 71, 0.06);
  border-radius: 19.5152px;
  display: flex;
  justify-content: space-between;
}
.teamProductCard > .top > .right-side > div > div {
  height: 65.96px;
  margin: 18px 0px 0px 18px;
}
.teamProductCard > .top > .right-side > div > div > p:nth-child(1) {
  margin: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.304924px;
  color: #272830;
}
.teamProductCard > .top > .right-side > div > div > p:nth-child(2) {
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 29.2727px;
  line-height: 36px;
  letter-spacing: 0.304924px;
  color: #272830;
}
.teamProductCard > .top > .right-side > div > img {
  width: 15px;
  height: 15px;
  margin-top: 18px;
  margin-right: 20px;
}
.teamProductCard > .btn {
  position: relative;
  width: 121px;
  height: 37px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.teamProductCard > .btn > button {
  background: linear-gradient(180deg, #60b893 0%, #7acca9 100%);
  border-radius: 8px;
  padding-left: 30px;
  height: 100%;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  width: 121px;
  border: none;
  font-weight: 600;
  cursor: pointer;
}
.teamProductCard > .btn > img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8.5px;
  left: 15px;
}
.teamProductCard > .filters {
  width: 95%;
  height: 40px;
}
.teamProductCard > .table-container {
  width: 95%;
}
.teamProductCard > .table-container > table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}
.teamProductCard > .filters > img {
  float: right;
  margin-left: 8px;
  cursor: pointer;
}
.teamProductCard > .table-container > table input {
  transform: scale(1.7);
  margin-right: 15px;
  position: relative;
  top: 2px;
}
.teamProductCard > .table-container > table > thead > tr > th {
  text-align: center;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9aa6b9;
}
.teamProductCard > .table-container > table > tbody > tr > td {
  text-align: center;
  height: 44px;
  background-color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9aa6b9;
}
.teamProductCard > .table-container > table > tbody > tr > td > button {
  color: #9aa6b9;
  background-color: #ffffff;
  border: none;
  font-size: 18px;
}
.teamProductCard
  > .table-container
  > table
  > tbody
  > tr
  > td:nth-child(1)
  > span {
  cursor: pointer;
}
.teamProductCard
  > .table-container
  > table
  > tbody
  > tr
  > td:nth-child(1)
  > span:hover {
  color: #747e8d;
}
.teamProductCard > .table-container > table > tbody > tr > td:nth-child(6) {
  position: relative;
}
.teamProductCard
  > .table-container
  > table
  > tbody
  > tr
  > td:nth-child(6)
  > img {
  position: absolute;
  top: 17.5px;
  left: 41px;
}
.teamTableCard > .table-container > table > tbody > tr > .td-buttons {
  display: flex;
  justify-content: center;
}
.teamProductCard
  > .table-container
  > table
  > tbody
  > tr
  > td:nth-child(6)
  > button {
  color: #7370d6;
  width: 70px;
  height: 22px;
  background: rgba(153, 151, 239, 0.29);
  border-radius: 76px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 25px;
  cursor: pointer;
}
.teamProductCard
  > .table-container
  > table
  > tbody
  > tr
  > td:nth-child(3)
  > div {
  width: 70px;
  height: 22px;
  padding: 0 3px;
  background: rgba(151, 239, 176, 0.29);
  border-radius: 76px;
  margin: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 22.5px;
  color: #49af5a;
}

.teamProductCard > .table-container > table > tbody > tr > td > .section {
  background-color: #ffffff;
  color: #000000;
  /* border: 1px solid red; */
}
.teamProductCard
  > .table-container
  > table
  > tbody
  > tr
  > td
  > .section
  > tr
  > th {
  width: 100%;
}
.teamProductCard
  > .table-container
  > table
  > tbody
  > tr
  > td
  > .section
  > tr
  > th:first-child {
  width: 80%;
  text-align: left;
  /* background: #EBEBEB; */
}
.teamProductCard
  > .table-container
  > table
  > tbody
  > tr
  > td
  > .section
  > tr
  > th:last-child {
  width: 100%;
  /* background: #EBEBEB; */
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teamProductCard
  > .table-container
  > table
  > tbody
  > tr
  > td
  > .section
  > tr
  > td:first-child {
  width: 80%;
  text-align: left;
}

.view-button {
  display: flex;
  width: fit-content;
  height: 22px;
  background: rgba(153, 151, 239, 0.29);
  border-radius: 76px;
  padding:0 10px;
  margin: auto;
  cursor: pointer;
}
.view-button > img {
  margin: auto 0;
}

.view-button > button {
  color: #7370d6;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left:10px;
  margin: auto 0;
  border: none;
  background-color: transparent;
}

@media only screen and (max-width: 1024px) {
  .teamProductCard > .top > .left-side > h2 {
    font-size: 20px;
  }
  .teamProductCard > .top > .left-side > p {
    font-size: 12px;
  }
  .teamProductCard > .top > .right-side > div {
    width: 100px;
  }
  .teamProductCard > .top > .right-side > div > div > p:nth-child(1) {
    font-size: 12px;
  }
  .teamProductCard > .top > .right-side > div > div > p:nth-child(2) {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .teamProductCard {
    width: calc(100% - 200px);
  }
  .teamProductCard > .top > .right-side > div > div > p:nth-child(1) {
    font-size: 12px;
  }
  .teamProductCard > .top > .right-side > div > div > p:nth-child(2) {
    font-size: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .leftNavigationBar {
    width: 140px;
    padding-left: 14px;
  }
  .teamProductCard > .top {
    height: auto;
    flex-wrap: wrap;
  }
  .teamProductCard > .top > .left-side > p {
    margin-top: 0;
  }
  .teamProductCard > .top > .right-side {
    width: 100%;
    justify-content: flex-start;
  }
  .teamProductCard > .top > .right-side > div {
    width: 50%;
    margin-bottom: 1rem;
  }
  .teamProductCard {
    padding-right: 20px;
    padding-left: 20px;
  }
  .teamProductCard > .table-container {
    width: 100%;
  }
  .teamProductCard > .table-container > table > thead > tr > th {
    width: 12% !important;
    padding-left: 0 !important;
  }
  .teamProductCard > .table-container > table > tr > td {
    width: 12% !important;
  }
}

@media screen and (max-width: 767px) {
  .teamProductCard {
    width: 100%;
  }
  .teamProductCard > .table-container {
    overflow: auto;
  }
  .teamProductCard > .table-container > table > thead > tr > th {
    width: 30% !important;
    padding-left: 0 !important;
  }
  .teamProductCard > .table-container > table > tbody > tr > td {
    width: 30% !important;
  }
}

/* @media screen and (max-width: 767px) {
    .teamProductCard{
        width: 100%;
        padding-left: 20px;
    }
}

@media screen and (max-width: 500px) {
    .teamProductCard>.top {
        flex-wrap: wrap;
        height: unset;
        margin-bottom: 10px;
    }
    .teamProductCard>.top>.right-side {
        width: 100%;
        justify-content: flex-start;
        gap: 20px;
    }
} */
