.password-input {
    margin-top: 21px;
}

.password-input>.icon-box {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 344px;
    font-size: 15px;
    height: 18px;
}

.password-input>.icon-box>p {
    margin-top: -3px;
}

.password-input>.icon-box>img {
    width: 13.6px;
    margin-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
}

.password-input>.input {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 384px;
    font-size: 15px;
    height: 18px;
    align-items: center;
}

.password-input>.input>input {
    margin-top: 5px;
    display: block;
    font-size: 15px;
    width: 344px;
    padding: 0 17px;
    height: 44px;
    border-radius: 59px;
    margin-right: 20px;
    margin-left: 0px;
}

.password-input .input img {
    width: 16px;
    margin-left: -50px;
}

@media screen and (max-width: 450px) {
    .password-input>.input>input {

        margin-left: 12px;
    }
    .password-input>.input {
        width: 100%;
    }
}