.triple-input {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 519px;
  margin-top: 21px;
}
.triple-input > div {
  width: 161px;
}
.triple-input > div > div {
  width: 100%;
  display: flex;
  font-size: 15px;
  height: 18px;
  margin-left: 17px;
}
.triple-input > .triple-input-left {
  margin-right: 17px;
}
.triple-input > .triple-input-middle {
  margin-right: 17px;
}
.triple-input > div > div > img {
  width: 13.6px;
  font-size: 15px;
  margin-right: 8px;
  height: 11.99px;
  margin-top: auto;
  margin-bottom: auto;
}
.triple-input > div > div > p {
  margin-top: -3px;
}
.triple-input > div > input {
  margin-top: 5px;
  display: block;
  font-size: 15px;
  width: 136px;
  padding: 0 17px;
  height: 44px;
  border: none;
  border-radius: 59px;
}
