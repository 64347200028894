.error-message{
    width: 425px;
    height:260px;
    background: #FFFFFF;
    border-radius: 9.55224px;
    padding-top:34px;
}
.error-message>.error-img{
    width: 72.84px;
    height: 72.84px;
    background: linear-gradient(180deg, #62BA95 0%, #77CAA6 100%);
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}
.error-message>.error-img>img{
    width: 24px;
    height: 24px;
    margin: auto;
    position: relative;
    top:24.42px;
}
.error-message>p{
    font-family:'Poppins';
    font-style: normal;
    font-weight: 500;
    width: 350px;
    height: 49px;
    color: #000000;
    font-size: 16.7164px;
    margin: 31.1px auto 22px auto;
}
.error-message>button{
    color:#FFFFFF;
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
    border-radius: 9.55224px;
    height:44.18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16.7164px;
    width:144.48px;
    border: none;
    font-weight:600;
    cursor: pointer;
}

@media screen and (max-width: 450px) {
    .error-message {
        width: calc(100vw - 20px);
        margin: auto;
    }
    .error-message>p {
        width: 95%;
    }
}