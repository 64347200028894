.add-assignee{
    width: 425px;
    height: 338px;
    background: #FFFFFF;
    border-radius: 9.55224px;
    padding-top: 25px;
}
.add-assignee>.top-img{
    width: 72.84px;
    height: 72.84px;
    margin: 0px auto;
    position: relative;
    box-shadow:0px 17.9104px 35.8209px rgba(111, 196, 159, 0.4);
    margin-bottom: 10px;
    border-radius: 50%;
}
.add-assignee>.top-img>img:nth-child(1){
    margin: 0;
    width: 72.84px;
    height: 72.84px;
}
.add-assignee>.top-img>img:nth-child(2){
    position: absolute;
    top: 19.705px;
    left: 19.705px;
    width:33.43px;
    height:33.43px;
}
.add-assignee>p{
    width: 220px;
    height: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16.7164px;
    text-align: center;
    color: #000000;
    margin: 0 auto;
    padding: 10px;
}
.add-assignee>.assignee-input{
    width: 351.04px;
    height: 58.43px;
    margin: 25px auto;
    position: relative;
}
.add-assignee>.assignee-input>input{
    width: 321.04px;
    height: 58.43px;
    background: #FFFFFF;
    padding: 0px 15px;
    border: 2.19403px solid #DEDEDE;
    border-radius: 9.55224px;
    font-size:18px;
}
.add-assignee>.assignee-input>img{
    position: absolute;
    left:323.06px;
    top:26.055px;
    width: 12.98px;
    height: 6.32px;
}
.add-assignee>.btns{ 
    height:44.18px;
    width: 310.45px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.add-assignee>.btns>button{
    border-radius: 9.55224px;
    height: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16.7164px;
    width:144.48px;
    border: none;
    font-weight:600;
    cursor: pointer;
}
.add-assignee>.btns>.left-btn{
    background: #EEEEEE;
}
.add-assignee>.btns>.right-btn{
    color:#FFFFFF;
    background: linear-gradient(180deg, #60B893 0%, #7ACCA9 100%);
}