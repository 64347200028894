.payment-subscription-card{
    width:calc(100% - 359px);
    padding-left: 49px;
    padding-top: 25px;
    background-color:  #F5F5F5;
    padding-bottom: 100px;
}
.payment-subscription-card>h2{
    font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
    font-size:34px;
}
.payment-subscription-card>p{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 150%;
    color: #606B85;
    margin-top: -24px;
    margin-left: 0px;
}
.payment-subscription-card>table{
    width:calc(100% - 40px);
    margin-top:16px;
    border-collapse: collapse;
}
.payment-subscription-card>table>thead>tr>th{
    text-align: center;
    height: 44px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9AA6B9;
}
.payment-subscription-card>table>tbody>tr>td{
    text-align: center;
    height: 44px;
    background-color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9AA6B9;
}

.payment-subscription-card>table>tbody>tr>td>button{
    background: linear-gradient(180deg, #6FBF9D 0%, #039342 100%);
    border-radius: 32px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding:5px 35px 5px 35px;
    border-radius: 10px;
    font-size: 15px;
    color:white;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 1024px){
    .payment-subscription-card>table>thead>tr>th{
        width: 12% !important;
        white-space: nowrap;
        padding-right: 1rem;
    }
    .payment-subscription-card>table>tbody>tr>td{
        width: 12% !important;
        white-space: nowrap;
        padding-right: 1rem;
    }
    .payment-subscription-card{
        overflow: auto;
    }

}

@media screen and (max-width: 767px) {
    .payment-subscription-card{
        width: 100%;
        padding-left: 20px;
    }
}