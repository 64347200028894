@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");
.teamTableCard {
  width: calc(100% - 359px);
  padding-left: 49px;
  padding-top: 25px;
  background-color: #f5f5f5;
  padding-bottom: 100px;
}
.teamTableCard > h2 {
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  font-size: 34px;
}
.teamTableCard > p {
  font-family: "poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 150%;
  color: #606b85;
  margin-top: -24px;
  margin-left: 5px;
}
.teamTableCard > .btn {
  position: relative;
  width: 121px;
  height: 37px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.teamTableCard > .btn > button {
  background: linear-gradient(180deg, #60b893 0%, #7acca9 100%);
  border-radius: 8px;
  padding-left: 30px;
  height: 100%;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  width: 121px;
  border: none;
  font-weight: 600;
  cursor: pointer;
}
.teamTableCard > .btn > img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 8.5px;
  left: 15px;
}
.teamTableCard > .filters {
  width: 98%;
  height: 40px;
  margin-top: 15px;
}

.teamTableCard > .filters > img {
  float: right;
  margin-left: 8px;
  cursor: pointer;
  margin-right: 10px;
}
.teamTableCard > .table-container {
  width: 98%;
  overflow: auto;
  /* margin-right: 10px; */
}
.teamTableCard > .table-container > table {
  width: 100%;
  margin-top: 16px;
  /* margin-right: 10px; */
  border-collapse: collapse;
}
.teamTableCard > .table-container > table input {
  transform: scale(1.7);
  margin-right: 15px;
  position: relative;
  top: 2px;
}
.teamTableCard > .table-container > table > thead > tr > th {
  text-align: left;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9aa6b9;
}
.teamTableCard > .table-container > table > tbody > tr > td {
  text-align: left;
  height: 44px;
  background-color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9aa6b9;
}
.teamTableCard > .table-container > table > tbody > tr > .td-buttons {
  display: flex;
  justify-content: center;
}
.teamTableCard > .table-container > table > tbody > tr > td > button {
  color: #9aa6b9;
  background-color: #ffffff;
  border: none;
  font-size: 18px;
}

@media only screen and (max-width: 1200px) {
  .teamTableCard {
    width: calc(100% - 200px);
  }
}

@media screen and (max-width: 767px) {
  .teamTableCard {
    width: 100%;
    padding-left: 20px;
  }
  .teamTableCard > .table-container > p {
    white-space: wrap !important;
  }
}
