.SubscriptionCard {
  width: 215px;
  height: 354px;
  border-radius: 25.89px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.SubscriptionCard > .type {
  width: 150.17px;
  height: 27.37px;
  margin-top: 25.15px;
  margin-left: 66.58px;
  text-align: center;
  line-height: 27.37px;
  background: linear-gradient(180deg, #5bb58e 0%, #039342 100%);
  border-radius: 22.1928px 0px 0px 22.1928px;
  font-size: 13.32px;
  color: #ffffff;
}
.SubscriptionCard > .price {
  font-size: 40.6867px;
  line-height: 100%;
  margin-top: 25.15px;
  margin-left: 33.47px;
  margin-bottom: 0;
  color: #1e9d59;
}
.SubscriptionCard > .price > .dollar {
  vertical-align: top;
  position: relative;
  bottom: 9px;
  font-size: 14.7952px;
  margin-top: 0px;
}
.SubscriptionCard > .price > .per {
  font-weight: 500;
  font-size: 7.39759px;
  position: relative;
  top: 8px;
  right: 30px;
}
.SubscriptionCard > .details {
  width: 90%;
  text-align: left;
  font-size: 10.3566px;
  line-height: 118.5%;
  color: #000000;
  margin: 0px auto 0px 20px;
}
.SubscriptionCard > ul {
  margin: 20px 0 20px 20px;
  padding: 0;
}
.SubscriptionCard > ul > li {
  display: flex;
  margin: 5px 0;
}
.SubscriptionCard > ul > li > div {
  width: 17.75px;
  height: 17.75px;
  background: linear-gradient(180deg, #5bb58e 0%, #039342 100%);
  border-radius: 50%;
}
.SubscriptionCard > ul > li > div > img {
  margin: auto;
  filter: invert(100%);
  width: 17px;
  margin-left: 3px;
}
.SubscriptionCard > ul > li > p {
  text-align: left;
  margin: auto 9px auto 9px;
  font-weight: 300;
  font-size: 9.87711px;
  line-height: 118.5%;
}
.SubscriptionCard > .quantity {
  width: 80.64px;
  height: 26.88px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -8px;
  display: flex;
}
.SubscriptionCard > .quantity > .minus {
  width: 26.88px;
  height: 100%;
  background: #cdf7e4;
  border-radius: 3.36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.SubscriptionCard > .quantity > .number {
  width: 26.88px;
  height: 100%;
  text-align: center;
}
.SubscriptionCard > .quantity > .plus {
  width: 26.88px;
  height: 100%;
  background: #cdf7e4;
  border-radius: 3.36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.SubscriptionCard > .btns {
  margin-top: 22px;
  width: 156.84px;
  margin-left: auto;
  margin-right: auto;
}
.SubscriptionCard > .btns > button {
  display: block;
  width: 91.9px;
  margin: auto;
  /*margin-left: 32.47px;*/
  height: 29.71px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11.1399px;
  color: #000000;
  background-color: white;
  border: 0.928328px solid #2aa264;
  border-radius: 29.7065px;
  cursor: pointer;
}
