.leftNavigationBar {
  width: 270px;
  height: 900px;
  padding-top: 35px;
  padding-left: 40px;
  background-color: #ffff;
}

.leftNavigationBar > div {
  font-size: 18px;
  height: 50px;
  margin-top: 0px;
  display: flex;
  vertical-align: middle;
  cursor: pointer;
}

.leftNavigationBar > div > img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.open {
  position: absolute;
  top: 10px;
  right: -12%;
  font-size: 1.2rem !important;
  display: none;
  width: fit-content;
  height: fit-content !important;
  padding: 5px 15px;
  border-radius: 0 5px 5px 0;
  background: #ffffff;
}

@media only screen and (max-width: 1200px) {
  .leftNavigationBar {
    width: 200px;
  }

  .leftNavigationBar > div {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .open {
    display: block;
  }
  .leftNavigationBar {
    position: relative;
    width: 70% !important;
    position: absolute;
    z-index: 1;
    transform: translateX(-100%);
    transition: all 0.4s ease-in-out;
  }
  .active {
    transform: translateX(0);
  }
}
