.big-modal-wrapper{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.50);
}

.big-modal-wrapper>.big-modal-form {
    z-index: 4;
    width: 1078px;
    height: 659px;
    margin: auto;
    margin-top: 40px;
}

.big-modal-wrapper>.big-modal-form>.big-form-body {
    text-align: center;
    width: 100%;
    height: 100%;
}
