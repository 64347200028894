.background{
    width:100%;
    position: absolute;
    top:0;
    z-index: -10;
    margin: 0;
    padding: 0;
    height: calc(100vh - 10px);
}
.background>img{
    width: 100%;
    height:100%;
}