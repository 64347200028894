@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@300&display=swap');
body {
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'segoe ui semibold';
    overflow-x: hidden !important;
    font-family: 'Poppins';
    font-style: normal;
}
